import React, { useState, useCallback, useContext, useEffect } from "react";
import styles from './AdminWeb.module.css';
import { updateObject, checkValidity } from '../../../../shared/shared';

import { useDispatch } from "react-redux";
import { AuthContext } from '../../../../context/AuthContext';

import Button from '../../../../components/UI/Button/Button';
import * as actions from "../../../../store/actions/actions";
import ImagesUploader from "../../../../components/UI/ImagesUploader/ImagesUploader";

const AdminWebCreate = (props) => {

    const { token } = useContext(AuthContext);

    const [fields, setFields] = useState({
        title: {
            value: "",
            validation: {
                required: "true",
                minLength: 2
            },
            valid: false,
            touched: false
        },
        orderPrio: {
            value: "",
            validation: {
                required: "true",
                number: "true"
            },
            valid: false,
            touched: false
        },
        description: {
            value: "",
            validation: {
            },
            valid: true,
            touched: false
        },
        link: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        image: {
            value: "",
            validation: {
                requiredFile: "true",
                category: ""
            },
            valid: false,
            touched: false
        },
        imagesUrl: []

    });

    const [formIsValid, setFormIsValid] = useState(false);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const handleChange = (value, field, files, category) => {

        const updatedField = updateObject(fields[field], {
            value: files ? files[0] : value,
            valid: checkValidity(value, fields[field].validation, files, category),
            touched: true
        });
        const updatedFields = updateObject(fields, { [field]: updatedField });

        let formIsValid = true;
        for (let field in updatedFields) {
            if (updatedFields[field].valid !== undefined)
                formIsValid = updatedFields[field].valid && formIsValid;
        }

        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }

    const handleSetFieldsImages = (newList, replace) => {
        setFields((prev) => ({
            ...prev,
            imagesUrl: replace ? newList : [...prev.imagesUrl, ...newList]
        }));
    };

    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }

    useEffect(() => {
        // console.log(fields.imagesUrl);
    }, [fields])

    useEffect(() => {
        if (!props.isEditing)
            return;


        const graphqlQuery = {
            query: `
            query Web($id:ID!){
            web(id:$id)
            {
                title
                orderPrio
                description
                link
                imageUrl
                images
            }
            }
            `
            , variables: {
                id: props.id
            }
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                // Batch updates to fields state
                setFields(prevFields => ({
                    ...prevFields,
                    title: {
                        ...prevFields.title,
                        value: data.data.web.title,
                        valid: checkValidity(data.data.web.title, prevFields.title.validation),
                        touched: true
                    },
                    orderPrio: {
                        ...prevFields.orderPrio,
                        value: data.data.web.orderPrio,
                        valid: checkValidity(data.data.web.orderPrio, prevFields.orderPrio.validation),
                        touched: true
                    },
                    description: {
                        ...prevFields.description,
                        value: data.data.web.description,
                        valid: checkValidity(data.data.web.description, prevFields.description.validation),
                        touched: true
                    },
                    link: {
                        ...prevFields.link,
                        value: data.data.web.link,
                        valid: checkValidity(data.data.web.link, prevFields.link.validation),
                        touched: true
                    },
                    image: {
                        ...prevFields.image,
                        value: data.data.web.imageUrl,
                        valid: checkValidity(data.data.web.imageUrl, prevFields.image.validation),
                        touched: true
                    },
                    imagesUrl: [
                        ...prevFields.imagesUrl,
                        ...data.data.web.images.map(imageUrl => ({
                            url: imageUrl,  // Assuming each `imageUrl` is a string
                            isFetched: true, // Set the initial `isFetched` status
                        }))
                    ]
                }));

            }).catch(err => {
                // console.log(err);
            })
        }

    }, [token, addFeedbackMessage, props.id, props.isEditing]);

    const submitHandler = (event) => {
        event.preventDefault();

        addFeedbackMessage({ typeMessage: "sending", message: "UPDATING" });


        const formData = new FormData();
        formData.append("image", fields.image.value);
        formData.append("category", "Web");  // Specify the folder for Cloudinary

        //*******************POST IMAGE */
        fetch(`${process.env.REACT_APP_API_URL}/post-image`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                if (data.error) {
                    addFeedbackMessage({ typeMessage: "error", message: data.error });
                    throw new Error(data.error);
                }
                const imageUrl = data.imageUrl;

                //*********************** POST IMAGES */

                const newFiles = fields.imagesUrl.filter((img) => !img.isFetched);
                const formData = new FormData();
                newFiles.forEach((file) => {
                    // console.log(file)
                    formData.append("images", file.file);
                });
                formData.append("category", "WebGallery");  // Specify the folder for Cloudinary


                fetch(`${process.env.REACT_APP_API_URL}/post-images`, {
                    method: "POST",
                    headers: {
                        'Authorization': 'Bearer ' + token
                    },
                    body: formData,
                })
                    .then((res) => res.json())
                    .then((data) => {
                        // console.log(data);
                        if (data.error) {
                            addFeedbackMessage({ typeMessage: "error", message: data.error });
                            throw new Error(data.error);
                        }


                        const imageUrls = data.imageUrls;

                        //************** POST CREATE OR EDIT */

                        let graphqlQuery = {
                            query: `
                        mutation CreateWeb($webInput: WebInputData!){
                        createWebData(webInput: $webInput){
                        title
                        orderPrio
                        description
                        link
                        imageUrl
                        images
                        }
                        }
                        `,
                            variables: {
                                webInput: {
                                    title: fields.title.value,
                                    orderPrio: Number(fields.orderPrio.value),
                                    description: fields.description.value,
                                    link: fields.link.value,
                                    imageUrl: imageUrl,
                                    images: imageUrls
                                }
                            }
                        };

                        if (props.isEditing) {
                            graphqlQuery = {
                                query: `
                            mutation UpdateWeb($id:ID!, $webInput: WebInputData!){
                            updateWebData(id:$id, webInput: $webInput){
                            title
                            orderPrio
                            description
                            link
                            imageUrl
                            images
                            }
                        }
                        `,
                                variables: {
                                    id: props.id,
                                    webInput: {
                                        title: fields.title.value,
                                        orderPrio: Number(fields.orderPrio.value),
                                        description: fields.description.value,
                                        link: fields.link.value,
                                        imageUrl: imageUrl ? imageUrl : 'undefined',
                                        images: imageUrls
                                    }
                                }
                            };
                        }

                        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + token
                            },
                            body: JSON.stringify(graphqlQuery)
                        }).then(res => {
                            return res.json();
                        }).then((data) => {
                            // Handle the response data here
                            // console.log(data);

                            if (data.errors) {
                                if (data.errors[0].extensions) {
                                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                                    const err = new Error(data.errors[0].extensions.message)
                                    throw err;
                                }
                                else {
                                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                                    const err = new Error("An Error Occured!")
                                    throw err;
                                }
                            }

                            if (data.data.updateWebData)
                                setFields((prevFields) => ({
                                    ...prevFields,
                                    imagesUrl: data.data.updateWebData.images.map(img => ({
                                        url: img,
                                        isFetched: true,
                                    }))
                                }));

                            addFeedbackMessage({ typeMessage: "sent", message: "SAVED" });
                        })
                            .catch((error) => {

                                // console.error("Error during fetch:", error);
                                addFeedbackMessage({ typeMessage: "error", message: "An error occurred during login. Please try again." });
                            });
                    }
                    )




            });

    }

    const deleteWebGallery = async (webGal_index) => {

        let graphqlQuery = {
            query: `
                    mutation DeleteWebGallery($id: ID!,$imageUrl:String!){
                    deleteWebGallery(id: $id,imageUrl:$imageUrl)
                    }
                    `,
            variables: {
                id: props.id,
                imageUrl: fields.imagesUrl[webGal_index].url
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(graphqlQuery)
            });

            const data = await response.json();

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }

            // console.log(data.data.deleteWebGallery);
            setFields((prevFields) => ({
                ...prevFields,
                imagesUrl: data.data.deleteWebGallery.map(img => ({
                    url: img,
                    isFetched: true,
                }))
            }));

            addFeedbackMessage({ typeMessage: "sent", message: "SAVED" });

        } catch (error) {
            console.error(error); // Log the error to the console
        }
    }

    const swapImagesWebGallery = async (fromIndex, toIndex) => {

        // console.log("from " + fromIndex + " to " + toIndex);
        let graphqlQuery = {
            query: `
                    mutation SwapImagesWebGallery($id: ID!,$fromIndex:Int!,$toIndex:Int!){
                    swapImagesWebGallery(id: $id,fromIndex:$fromIndex,toIndex:$toIndex)
                    }
                    `,
            variables: {
                id: props.id,
                fromIndex: fromIndex,
                toIndex: toIndex
            }
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify(graphqlQuery)
            });

            const data = await response.json();

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }

            setFields((prevFields) => ({
                ...prevFields,
                imagesUrl: data.data.swapImagesWebGallery.map(img => ({
                    url: img,
                    isFetched: true,
                }))
            }));

            addFeedbackMessage({ typeMessage: "sent", message: "SAVED" });

        } catch (error) {
            console.error(error); // Log the error to the console
        }
    }


    const pageContent = (
        <React.Fragment>
            <h1>
                {props.isEditing ? "EDIT WEB" : "CREATE WEB"}
            </h1>
            <div className={styles.ContactForm}>
                <form onSubmit={submitHandler} autoComplete={"off"}>
                    <ul>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Order Priority*</label>
                            <input
                                name="orderPrio"
                                type="text"
                                placeholder="Order Priority"
                                onChange={(event) => handleChange(event.target.value, "orderPrio")}
                                value={fields["orderPrio"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["orderPrio"].valid, fields["orderPrio"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Title*</label>
                            <input
                                name="title"
                                type="text"
                                placeholder="Title"
                                onChange={(event) => handleChange(event.target.value, "title")}
                                value={fields["title"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["title"].valid, fields["title"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Icon*</label>
                            <input
                                className={[styles.FileButton]}
                                type="file"
                                onChange={(event) => handleChange("", "image", event.target.files, "Web")} />
                            {fields["image"].touched && !fields["image"].valid && (
                                <label className={styles.Error}>Invalid file. Please upload a JPEG, PNG, or GIF under the size limit.</label>
                            )}
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                            <div className={styles.ImageZone}>
                                <div className={styles.Thumbnail}>
                                    <img className={styles.ThumbnailImg}
                                        src={fields["image"].value}
                                        alt="" />
                                </div>
                            </div>
                        </li>

                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Description</label>
                            <textarea
                                name="description"
                                type="text"
                                placeholder="Description"
                                onChange={(event) => handleChange(event.target.value, "description")}
                                value={fields["description"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["description"].valid, fields["description"].touched)].join(' ')}>
                            </label>
                        </li>

                        <li className={[styles.ContactFormLI3Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Link*</label>
                            <input
                                name="link"
                                type="text"
                                placeholder="Link"
                                onChange={(event) => handleChange(event.target.value, "link")}
                                value={fields["link"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["link"].valid, fields["link"].touched)].join(' ')}>
                            </label>
                        </li>

                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Images</label>
                            <ImagesUploader setImagesList={handleSetFieldsImages} />
                        </li>
                        <input
                            name="id"
                            type="hidden"
                            value={props.id} />
                    </ul>
                    <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                        style={{ position: 'relative' }}>SAVE</Button>
                </form>
                <ul>
                    <li className={[styles.ContactFormLI2Animation].join(' ')}>
                        <label className={[styles.LabelName]}>Live update manipulation</label>
                        <ImagesUploader
                            imagesList={fields.imagesUrl}
                            setImagesList={handleSetFieldsImages}
                            onDeleteWebGallery={deleteWebGallery}
                            onSwapWebGallery={swapImagesWebGallery}
                            liveManipulation={true}
                        />
                    </li>
                </ul>
            </div>

        </React.Fragment>

    );


    return (
        <React.Fragment>
            {pageContent}
        </React.Fragment>
    )
}


export default AdminWebCreate;