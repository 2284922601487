import React, { useState, useCallback, useEffect } from "react";
import { updateObject, checkValidity } from '../../shared/shared';
import emailjs from 'emailjs-com';

import styles from './ContactMe.module.css';
import stylesTag from "../../components/UI/Tag/Tags.module.css";
import "./leaflet.css";

import MyMap from "../../components/MyMap/MyMap";
import Tag from "../../components/UI/Tag/Tag";
import Button from '../../components/UI/Button/Button'
import FeedbackMessages from "../../components/UI/FeedbackMessage/FeedbackMessages";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions/actions";

const ContactMe = () => {
    
    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });

    const [content, setContent] = useState({
        contactDesc: "",
        locationLong: "",
        locationLat: ""
    });

    useEffect(() => {
        const graphqlQuery = {
            query: `
            {
            generalInfoData{
                contactDesc
                locationLong
                locationLat
            }}
            `
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then(data => {

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }

            // Batch updates to fields state
            setContent({
                contactDesc: data.data.generalInfoData.contactDesc,
                locationLong: data.data.generalInfoData.locationLong,
                locationLat: data.data.generalInfoData.locationLat
            });

        }).catch(err => {
            // console.log(err);
        })

    }, [addFeedbackMessage]);

    const [fields, setFields] = useState({
        name: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        email: {
            value: "",
            validation: {
                required: "true",
                email: "true",
            },
            valid: false,
            touched: false
        },
        subject: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        message: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);

    const handleChange = (event, field) => {
        const updatedField = updateObject(fields[field], {
            value: event.target.value,
            valid: checkValidity(event.target.value, fields[field].validation),
            touched: true
        })
        const updatedFields = updateObject(fields, { [field]: updatedField });

        let formIsValid = true;
        for (let field in updatedFields) {
            formIsValid = updatedFields[field].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }


    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }

    const resetForm = () => {
        let updatedFields = { ...fields };
        for (let field in updatedFields) {
            let clearedField = updateObject(fields[field],
                {
                    value: "",
                    validation: fields[field].validation,
                    valid: false,
                    touched: false

                })
            updatedFields = updateObject(updatedFields, { [field]: clearedField });
            // console.log("test " + updatedFields[field].value);
        }

        setFields(updatedFields);
        setFormIsValid(false);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        addFeedbackMessage({ typeMessage: "sending", message: "SENDING..." });
        emailjs.sendForm(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            event.target,
            process.env.REACT_APP_EMAILJS_USER_ID
        )
            .then(result => {
                if (result.text === "OK") {
                    addFeedbackMessage({ typeMessage: "sent", message: "SENT!" });
                    resetForm();
                }
            },
                error => {
                    addFeedbackMessage({ typeMessage: "error", message: "SOMETHING WENT WRONG!" });
                    // console.log(error);
                })

    }
    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
            <div className={styles.ContactMeContainer}>
                <div className={styles.TextZone}>
                    <h1>
                        Contact Me
                    </h1>
                    <p className={styles.P1}>{content["contactDesc"]}</p>
                    <div className={styles.ContactForm}>
                        <form onSubmit={submitHandler} autoComplete={"off"}>
                            <ul>

                                <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                                    <input
                                        name="name"
                                        type="text" placeholder="Name"
                                        onChange={(event) => handleChange(event, "name")}
                                        value={fields["name"].value} />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["name"].valid, fields["name"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={[styles.Half, styles.ContactFormLI2Animation].join(' ')}>
                                    <input
                                        name="email"
                                        type="email"
                                        placeholder="Email"
                                        onChange={(event) => handleChange(event, "email")}
                                        value={fields["email"].value} />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["email"].valid, fields["email"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={styles.ContactFormLI3Animation}>
                                    <input
                                        name="subject"
                                        type="text"
                                        placeholder="Subject"
                                        onChange={(event) => handleChange(event, "subject")}
                                        value={fields["subject"].value} />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["subject"].valid, fields["subject"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={styles.ContactFormLI4Animation}>
                                    <textarea name="message" id="" cols="30" rows="10"
                                        placeholder="Message"
                                        onChange={(event) => handleChange(event, "message")}
                                        value={fields["message"].value}
                                    />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["message"].valid, fields["message"].touched)].join(' ')}>
                                    </label>
                                </li>
                            </ul>
                            <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                                style={{ position: 'relative' }}>SEND</Button>
                        </form>
                    </div>
                </div>
                <div className={styles.MapZone}>
                    <MyMap locationLong={content["locationLong"]} locationLat={content["locationLat"]} />
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
            <FeedbackMessages />
        </React.Fragment>
    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/contactMe" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default ContactMe;