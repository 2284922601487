import React, { useState } from "react";
import styles from './GeneralElement.module.css';
import IconEdit from '../../../assets/Icons/Icon_Edit.png'
import IconDelete from '../../../assets/Icons/Icon_Delete.png'

const GeneralElement = (props) => {


    const [elementHover, setElementHover] = useState(false);

    let classesContainer = [styles.Container];
    let classesThumbnailImg = [styles.ThumbnailImg];

    const onHoverElementHandler = (value) => {

        setElementHover(value);

    }

    if (elementHover) {
        classesContainer.push(styles.ContainerHover);
        classesThumbnailImg.push(styles.ThumbnailImgHover);

    } else {
        classesContainer = [styles.Container];
        classesThumbnailImg = [styles.ThumbnailImg];
    }

    let classOptionalOverride = !props.optional ? { width: "auto" } : {};


    return (
        <div
            className={classesContainer.join(' ')}
            onMouseEnter={() => onHoverElementHandler(true)}
            onMouseLeave={() => onHoverElementHandler(false)}>
            <div className={styles.Content}>
                <div className={styles.Data}>
                    <div className={styles.Column0}>
                        {props.orderPrio}
                    </div>
                    <div className={styles.ImageZone}>
                        <div className={styles.Thumbnail}>
                            <img className={classesThumbnailImg.join(' ')}
                                src={props.imageUrl}
                                alt="" />
                        </div>

                    </div>
                    <div className={styles.Column1} style={classOptionalOverride}>
                        {props.title}
                    </div>
                    {props.optional ?
                        <div className={styles.Column2}>
                            {props.optional}
                        </div> :
                        null
                    }

                </div>
                <div className={styles.ButtonsSection}>
                    <button className={styles.IconButtonEdit} onClick={props.onEdit}><img className={styles.ImageIcon} src={IconEdit} alt="" /></button>
                    <button className={styles.IconButtonDelete} onClick={props.onDelete}><img className={styles.ImageIcon} src={IconDelete} alt="" /></button>

                </div>

            </div>

        </div>
    )
}

export default GeneralElement;