import React, { useState } from "react";
import styles from './AdminMyWork.module.css';
import stylesToolBar from './AdminMyWorkToolbar.module.css';

import AdminGames from './AdminMyGames/AdminGames'
import AdminWebs from './AdminMyWebs/AdminWebs'


const AdminMyWork = () => {

    const [activeSection, setActiveSection] = useState(0);

    const selectedContent = () => {
        switch (activeSection) {
            case 0:
                return <AdminGames />
            case 1:
                return <AdminWebs />
            default:
                return <AdminGames />
        }
    };

    const classBtnActive = () => {
        switch (activeSection) {
            case 0:
                return stylesToolBar.UnderlineActiveBtn;
            case 1:
                return stylesToolBar.UnderlineActiveBtn1;
            default:
                return stylesToolBar.UnderlineActiveBtn;
        }
    }





    const pageContent = (
        <React.Fragment>
            <div className={stylesToolBar.SectionSelection}>
                <div>
                    <span onClick={() => setActiveSection(0)}
                        className={[stylesToolBar.SectionBtn, activeSection === 0 ? stylesToolBar.SectionBtnActive : null].join(' ')}>
                        <span
                            className={stylesToolBar.SectionSelectionBtn0}>GAMING</span></span>
                    <span onClick={() => setActiveSection(1)}
                        className={[stylesToolBar.SectionBtn, activeSection === 1 ? stylesToolBar.SectionBtnActive : null].join(' ')}>
                        <span className={stylesToolBar.SectionSelectionBtn1}>WEB</span></span>
                    <div className={[stylesToolBar.UnderlineActiveBtn, classBtnActive()].join(' ')}>
                    </div>
                </div>
            </div>
            <div className={styles.ContactMeContainer}>
                    {selectedContent()}
            </div>
        </React.Fragment>

    );


    return (
        <React.Fragment>
            {pageContent}
        </React.Fragment>
    )
}


export default AdminMyWork;