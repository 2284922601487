import React, { useRef, useState, useEffect } from "react";
import styles from "./ImagesUploader.module.css"
import ImageUploaderElement from "./ImageUploaderElement/ImageUploaderElement";

const ImagesUploader = (props) => {

    const fileInputRef = useRef();

    const [imagesListLocal, setImagesListLocal] = useState([]);
    const [draggingIndex, setDraggingIndex] = useState(-1);
    const [isDragging, setIsDragging] = useState(false);
    const [newPlacingIndex, setNewPlacingIndex] = useState(-1);
    const [initProps, setInitProps] = useState(false);


    const moveElement = (arr, fromIndex, toIndex) => {
        const newArr = [...arr]; // Create a shallow copy of the array
        const [movedElement] = newArr.splice(fromIndex, 1); // Remove the element from 'fromIndex'
        if (fromIndex < toIndex) {
            toIndex -= 1;
        }

        // Insert the element at 'toIndex'
        newArr.splice(toIndex, 0, movedElement);

        return newArr;
    };

    const handleMove = (fromIndex, toIndex) => {
        if (props.liveManipulation)
            props.onSwapWebGallery(fromIndex, toIndex);
        else {
            setImagesListLocal(moveElement(imagesListLocal, fromIndex, toIndex));
        }
    };

    const onDragImageStart = (index) => {
        setIsDragging(true);
        setDraggingIndex(index);
    }

    const onDragImage = (newIndex) => {
        if (!isDragging)
            return
        if (newPlacingIndex !== newIndex) {
            setNewPlacingIndex(newIndex);
        }

    }

    const onDragImageEnd = () => {
        handleMove(draggingIndex, newPlacingIndex)
        setIsDragging(false);
        setDraggingIndex(-1);
        setNewPlacingIndex(-1);
    }

    const deleteHandleLocal = (indexToDelete) => {
        const newArr = imagesListLocal.filter((img, index) => index !== indexToDelete);

        setImagesListLocal(newArr);
    }

    const onDeleteWebGalleryHandler = (index) => {
        props.onDeleteWebGallery(index);
        setInitProps(false);
    }

    // Prevent the fact that we can drop a draggable into another input field
    useEffect(() => {
        const preventDefaultBehavior = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        window.addEventListener('dragover', preventDefaultBehavior);
        window.addEventListener('drop', preventDefaultBehavior);

        return () => {
            window.removeEventListener('dragover', preventDefaultBehavior);
            window.removeEventListener('drop', preventDefaultBehavior);
        };
    }, []);

    useEffect(() => {
        if (props.imagesList) {
            const newImagesFetchedOnly = props.imagesList.filter(img => img.isFetched)
            setImagesListLocal(newImagesFetchedOnly);
            setInitProps(true);
        }
    }, [props.imagesList, initProps]);

    let imagesRenderer = imagesListLocal.map((img, index) => (
        <React.Fragment key={index}>
            {/* Insert the placeholder before the current image if needed */}
            <ImageUploaderElement
                key={index}
                img={img.isFetched ? img.url : URL.createObjectURL(img.file)}
                index={index}
                onDragStart={onDragImageStart}
                onDrag={onDragImage}
                onDragEnd={onDragImageEnd}
                isDragging={isDragging}
                draggingIndex={draggingIndex}
                imagesListLocal={imagesListLocal}
                onDelete={img.isFetched ? onDeleteWebGalleryHandler : deleteHandleLocal}
            >
                {newPlacingIndex === index && (
                    <div className={styles.Placeholder} />
                )}
            </ImageUploaderElement>
        </React.Fragment>
    ));

    // Add the placeholder at the end if needed
    if (newPlacingIndex === imagesListLocal.length) {
        imagesRenderer.push(
            <div key="end-placeholder" className={styles.PlaceholderEnd} />
        );
    }


    const handleInputChange = (e, files) => {
        if (files) {
            const newFiles = Array.from(files).map((file) => ({
                file,
                isFetched: false, // Mark newly added files
            }));

            if (props.setImagesList)
                props.setImagesList(newFiles);

            setImagesListLocal(prev => [
                ...prev,
                ...newFiles
            ]);

            // Reset the input value before opening the file dialog
            fileInputRef.current.value = '';
            fileInputRef.current.click(); // Open the file dialog

        }
    }

    let componentContent = (
        <div className={[styles.Container]}>
            <div className={[styles.ImagesUploaderContainer]}>
                {imagesRenderer}
            </div>
            {!props.liveManipulation ? <><button
                type="button"
                className={styles.FileButton}
                onClick={() => fileInputRef.current.click()}
            >
                + ADD
            </button>
                <input
                    ref={fileInputRef}
                    className={styles.FileButton}
                    style={{ display: 'none' }}
                    type="file"
                    multiple
                    onChange={(event) => handleInputChange(event, event.target.files)}
                /> </> : ""}

        </div>
    );

    return (
        <React.Fragment>
            {componentContent}
        </React.Fragment>
    )
}


export default ImagesUploader;