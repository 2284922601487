import React, { useState, useCallback, useContext, useEffect } from "react";
import styles from './AdminGame.module.css';
import { updateObject, checkValidity } from '../../../../shared/shared';

import { useDispatch } from "react-redux";
import { AuthContext } from '../../../../context/AuthContext';

import Button from '../../../../components/UI/Button/Button';
import * as actions from "../../../../store/actions/actions";

const AdminGameCreate = (props) => {

    const { token } = useContext(AuthContext);

    const [fields, setFields] = useState({
        title: {
            value: "",
            validation: {
                required: "true",
                minLength: 2
            },
            valid: false,
            touched: false
        },
        orderPrio: {
            value: "",
            validation: {
                required: "true",
                number: "true"
            },
            valid: false,
            touched: false
        },
        image: {
            value: "",
            validation: {
                requiredFile: "true",
                category: ""
            },
            valid: false,
            touched: false
        },
        duration: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        description: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        videoLink: {
            value: "",
            validation: {

            },
            valid: true,
            touched: false
        }

    });

    const [formIsValid, setFormIsValid] = useState(false);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const handleChange = (value, field, files, category) => {

        const updatedField = updateObject(fields[field], {
            value: files ? files[0] : value,
            valid: checkValidity(value, fields[field].validation, files, category),
            touched: true
        });
        const updatedFields = updateObject(fields, { [field]: updatedField });

        let formIsValid = true;
        for (let field in updatedFields) {
            formIsValid = updatedFields[field].valid && formIsValid;
        }

        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }

    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }


    useEffect(() => {
        if (!props.isEditing)
            return;


        const graphqlQuery = {
            query: `
            query Game($id:ID!){
            game(id:$id)
            {
                title
                orderPrio
                description
                duration
                videoLink
                imageUrl
            }
            }
            `
            , variables: {
                id: props.id
            }
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                // Batch updates to fields state
                setFields(prevFields => ({
                    ...prevFields,
                    title: {
                        ...prevFields.title,
                        value: data.data.game.title,
                        valid: checkValidity(data.data.game.title, prevFields.title.validation),
                        touched: true
                    },
                    orderPrio: {
                        ...prevFields.orderPrio,
                        value: data.data.game.orderPrio,
                        valid: checkValidity(data.data.game.orderPrio, prevFields.orderPrio.validation),
                        touched: true
                    },
                    description: {
                        ...prevFields.description,
                        value: data.data.game.description,
                        valid: checkValidity(data.data.game.description, prevFields.description.validation),
                        touched: true
                    },
                    duration: {
                        ...prevFields.duration,
                        value: data.data.game.duration,
                        valid: checkValidity(data.data.game.duration, prevFields.duration.validation),
                        touched: true
                    },
                    videoLink: {
                        ...prevFields.videoLink,
                        value: data.data.game.videoLink,
                        valid: checkValidity(data.data.game.videoLink, prevFields.videoLink.validation),
                        touched: true
                    },
                    image: {
                        ...prevFields.image,
                        value: data.data.game.imageUrl,
                        valid: checkValidity(data.data.game.imageUrl, prevFields.image.validation),
                        touched: true
                    }
                }));

            }).catch(err => {
                // console.log(err);
            })
        }

    }, [token, addFeedbackMessage, props.id, props.isEditing]);

    const submitHandler = (event) => {
        event.preventDefault();

        addFeedbackMessage({ typeMessage: "sending", message: "UPDATING" });


        const formData = new FormData();
        formData.append("image", fields.image.value);
        formData.append("category", "Game");  // Specify the folder for Cloudinary


        fetch(`${process.env.REACT_APP_API_URL}/post-image`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + token
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((data) => {
                // console.log(data);
                if (data.error) {
                    addFeedbackMessage({ typeMessage: "error", message: data.error });
                    throw new Error(data.error);
                }

                const imageUrl = data.imageUrl;

                // *********************
                let graphqlQuery = {
                    query: `
                mutation CreateGame($gameInput: GameInputData!){
                createGameData(gameInput: $gameInput){
                title
                orderPrio
                duration
                description
                videoLink
                imageUrl
                }
                }
                `,
                    variables: {
                        gameInput: {
                            title: fields.title.value,
                            orderPrio: Number(fields.orderPrio.value),
                            description: fields.description.value,
                            duration: fields.duration.value,
                            videoLink: fields.videoLink.value,
                            imageUrl: imageUrl,
                        }
                    }
                };

                if (props.isEditing) {
                    graphqlQuery = {
                        query: `
                    mutation UpdateGame($id:ID!, $gameInput: GameInputData!){
                    updateGameData(id:$id, gameInput: $gameInput){
                    title
                    orderPrio
                    duration
                    description
                    videoLink
                    imageUrl
                    }
                }
                `,
                        variables: {
                            id: props.id,
                            gameInput: {
                                title: fields.title.value,
                                orderPrio: Number(fields.orderPrio.value),
                                description: fields.description.value,
                                duration: fields.duration.value,
                                videoLink: fields.videoLink.value,
                                imageUrl: imageUrl ? imageUrl : 'undefined',
                            }
                        }
                    };
                }

                fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token
                    },
                    body: JSON.stringify(graphqlQuery)
                }).then(res => {
                    return res.json();
                }).then((data) => {
                    // Handle the response data here
                    // console.log(data);

                    if (data.errors) {
                        if (data.errors[0].extensions) {
                            addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                            const err = new Error(data.errors[0].extensions.message)
                            throw err;
                        }
                        else {
                            addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                            const err = new Error("An Error Occured!")
                            throw err;
                        }
                    }

                    addFeedbackMessage({ typeMessage: "sent", message: "SAVED" });
                })
                    .catch((error) => {

                        // console.error("Error during fetch:", error);
                        addFeedbackMessage({ typeMessage: "error", message: "An error occurred during login. Please try again." });
                    });


            });

    }

    const pageContent = (
        <React.Fragment>
            <h1>
                {props.isEditing ? "EDIT Game" : "CREATE Game"}
            </h1>
            <div className={styles.ContactForm}>
                <form onSubmit={submitHandler} autoComplete={"off"}>
                    <ul>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Order Priority</label>
                            <input
                                name="orderPrio"
                                type="text"
                                placeholder="Order Priority"
                                onChange={(event) => handleChange(event.target.value, "orderPrio")}
                                value={fields["orderPrio"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["orderPrio"].valid, fields["orderPrio"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Title</label>
                            <input
                                name="title"
                                type="text"
                                placeholder="Title"
                                onChange={(event) => handleChange(event.target.value, "title")}
                                value={fields["title"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["title"].valid, fields["title"].touched)].join(' ')}>
                            </label>
                        </li>

                        <li className={[styles.ContactFormLI3Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Duration</label>
                            <input
                                name="duration"
                                type="text"
                                placeholder="Duration"
                                onChange={(event) => handleChange(event.target.value, "duration")}
                                value={fields["duration"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["duration"].valid, fields["duration"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Icon</label>
                            <input
                                className={[styles.FileButton]}
                                type="file"
                                onChange={(event) => handleChange("", "image", event.target.files, "Game")} />
                            {fields["image"].touched && !fields["image"].valid && (
                                <label className={styles.Error}>Invalid file. Please upload a JPEG, PNG, or GIF under the size limit.</label>
                            )}
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')}>
                            <div className={styles.ImageZone}>
                                <div className={styles.Thumbnail}>
                                    <img className={styles.ThumbnailImg}
                                        src={fields["image"].value}
                                        alt="" />
                                </div>
                            </div>
                        </li>

                        <li className={[styles.ContactFormLI3Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Description</label>
                            <textarea
                                name="description"
                                type="text"
                                placeholder="Description"
                                onChange={(event) => handleChange(event.target.value, "description")}
                                value={fields["description"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["description"].valid, fields["description"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Video Link</label>
                            <input
                                name="videoLink"
                                type="text"
                                placeholder="Video Link"
                                onChange={(event) => handleChange(event.target.value, "videoLink")}
                                value={fields["videoLink"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["videoLink"].valid, fields["videoLink"].touched)].join(' ')}>
                            </label>
                        </li>
                        <input
                            name="id"
                            type="hidden"
                            value={props.id} />
                    </ul>
                    <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                        style={{ position: 'relative' }}>SAVE</Button>
                </form>
            </div>
        </React.Fragment>

    );


    return (
        <React.Fragment>
            {pageContent}
        </React.Fragment>
    )
}


export default AdminGameCreate;