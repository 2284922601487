import React, { useEffect, useState, useCallback } from "react";

import * as actions from "../../../../store/actions/actions";
import { useDispatch } from "react-redux";
import styles from "./ProjectsListGaming.module.css"

import ProjectGaming from "./Project/ProjectGaming";

const ProjectsListGaming = (props) => {

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const [projectsData, setProjectsData] = useState();

    useEffect(() => {

        const fetchGames = async () => {

            const graphqlQuery = {
                query: `
            query Games{
            games{
            games {
                _id
                title
                duration
                description
                imageUrl
                videoLink
            }
            }
            }
            `
            };

            try {

                const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(graphqlQuery)
                })

                const data = await response.json();


                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                setProjectsData(data.data.games.games);


            } catch (err) {
                // console.log(err);
            }
        }

        fetchGames();

    }, [addFeedbackMessage]);


    const [index, setIndex] = useState(0)
    const [projectDataArray, setProjectDataArray] = useState([]);
    useEffect(() => {

        if (!projectsData)
            return;
        const timeout = setTimeout(() => {
            setProjectDataArray(projectDataArray.concat(projectsData[index]))
            if (index < projectsData.length - 1)
                setIndex(index + 1)

        }, 300);

        return () => clearTimeout(timeout)
        // eslint-disable-next-line
    }, [index, projectsData])


    let styleClasses = [styles.ProjectsList];
    if (props.styles)
        styleClasses.push(props.styles)

    let projects;
    if (projectsData && projectsData.length > 0)
        projects = projectDataArray.map(project => (
            <ProjectGaming key={project.title} duration={project.duration} thumbnail={project.imageUrl} title={project.title}
                description={project.description}
                link={project.videoLink} />
        ))

    return (
        <div className={styleClasses.join(' ')}>
            {projects}

        </div>
    )
}

export default ProjectsListGaming;