import React, { useState, useEffect } from "react";
import styles from './ProgressBars.module.css'

import ProgressBar from "./ProgressBar/ProgressBar";

const ProgressBars = (props) => {

    const [index, setIndex] = useState(0)

    const [skillDataArray, setSkillDataArray] = useState([]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setSkillDataArray(skillDataArray.concat(props.skills[index]))
            if (index < props.skills.length - 1)
                setIndex(index + 1)
        }, 300);

        return () => clearTimeout(timeout)

        // eslint-disable-next-line
    }, [index, props.skills])

    let skills;
    if (props.skills.length > 0)
        skills = skillDataArray.map(skill => (
            <ProgressBar key={skill._id} icon={skill.imageUrl} name={skill.title} value={skill.skillLevel} />
        )

            //     let skills = skillDataArray.map(skill => (
            //     <ProgressBar key={skill.name} icon={skill.icon} name={skill.name} value={skill.value} />
            // )
        )

    return (
        <div className={styles.ProgressBarsContainer}>
            {skills}
        </div>
    )
}

export default ProgressBars;