import React, { useState } from "react";
import styles from './NavigationItem.module.css'
import { NavLink } from 'react-router-dom';
import { Transition } from "react-transition-group";


const NavigationItem = props => {

    const nodeRef = React.useRef(null);

    const [showText, setShowText] = useState(false);
    const showMouseEnterHandler = () => {
        setShowText(true);
    }
    const showMouseExitHandler = () => {
        setShowText(false);
    }

    return (
        <React.Fragment>
            <Transition
                nodeRef={nodeRef}
                in={showText}
                timeout={200}>
                {state => {
                    const showHover = state === 'entered' ? true : state !== 'exited' ? true : null;
                    return (
                        <React.Fragment>
                            <NavLink
                                ref={nodeRef}
                                to={props.link}
                                exact
                                onClick={props.func ? props.func : undefined}
                                className={styles.NavigationItem}
                                activeClassName={styles.active}
                                onMouseEnter={showMouseEnterHandler}
                                onMouseLeave={showMouseExitHandler}>
                                <p className={showHover ? styles.LinkEnter : styles.LinkExit}>{props.text}</p>
                                <img className={showHover ? styles.LinkExit : styles.LinkEnter} src={props.icon} alt="" />

                            </NavLink>
                        </React.Fragment>
                    )
                }}
            </Transition>
        </React.Fragment>
    )
};

export default NavigationItem;