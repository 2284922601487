import React, { useState, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { updateObject, checkValidity } from '../../../shared/shared';

import styles from './Login.module.css';
import stylesTag from "../../../components/UI/Tag/Tags.module.css";

import Tag from "../../../components/UI/Tag/Tag";
import Button from '../../../components/UI/Button/Button'
import FeedbackMessages from "../../../components/UI/FeedbackMessage/FeedbackMessages";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../context/AuthContext';
import * as actions from "../../../store/actions/actions";

const Login = () => {
 
    const { setIsAuth, setToken, isAuth, token, setAutoLogout } = useContext(AuthContext);
    const history = useHistory();

    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });

    useEffect(() => {
        const graphqlQuery = {
            query: `
            {
            tokenCheck}
            `
        };
        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                if (data.data.tokenCheck) {
                    history.push('/adminPanel'); // Redirect to root if authenticated
                }
            }).catch(err => {
                // console.log(err);
                // logout();

            })
        }

    }, [isAuth, history, token]);

    const [fields, setFields] = useState({
        username: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        },
        password: {
            value: "",
            validation: {
                required: "true",
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const handleChange = (event, field) => {
        const updatedField = updateObject(fields[field], {
            value: event.target.value,
            valid: checkValidity(event.target.value, fields[field].validation),
            touched: true
        })
        const updatedFields = updateObject(fields, { [field]: updatedField });

        let formIsValid = true;
        for (let field in updatedFields) {
            formIsValid = updatedFields[field].valid && formIsValid;
        }
        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }


    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }

    const submitHandler = (event) => {
        event.preventDefault();

        const authData = {
            username: fields.username.value,
            password: fields.password.value
        };

        addFeedbackMessage({ typeMessage: "sending", message: "LOGGING IN..." });
        const graphqlQuery = {
            query: `
            query FetchLogin($username:String!,$password:String!){
            login(username:$username ,password:$password){
            userId
            token
            }
            }
            `,
            variables: {
                username: authData.username,
                password: authData.password
            }
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then((data) => {
            // Handle the response data here

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }
            // console.log(data);

            setToken(data.data.login.token);
            setIsAuth(true);
            localStorage.setItem('token', data.data.login.token);
            localStorage.setItem('userId', data.data.login.userId);

            const remainingMilliseconds = 60 * 60 * 1000;
            const expiryDate = new Date(
                new Date().getTime() + remainingMilliseconds
            );
            localStorage.setItem('expiryDate', expiryDate.toISOString());
            setAutoLogout(remainingMilliseconds);

            history.push('/adminPanel'); // Redirect to admin root after successful login
        })
            .catch((error) => {

                // console.error("Error during fetch:", error);
                addFeedbackMessage({ typeMessage: "error", message: "An error occurred during login. Please try again." });
            });


    }
    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
            <div className={styles.ContactMeContainer}>
                <div className={styles.TextZone}>
                    <h1>
                        Login
                    </h1>
                    <div className={styles.ContactForm}>
                        <form onSubmit={submitHandler} autoComplete={"off"}>
                            <ul>
                                <li className={[styles.ContactFormLI1Animation].join(' ')}>
                                    <input
                                        name="username"
                                        type="text"
                                        placeholder="Username"
                                        onChange={(event) => handleChange(event, "username")}
                                        value={fields["username"].value} />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["username"].valid, fields["username"].touched)].join(' ')}>
                                    </label>
                                </li>
                                <li className={[styles.ContactFormLI2Animation].join(' ')}>
                                    <input
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        onChange={(event) => handleChange(event, "password")}
                                        value={fields["password"].value} />
                                    <label
                                        className={[styles.LabelFeedBack, validationClasses(fields["password"].valid, fields["password"].touched)].join(' ')}>
                                    </label>
                                </li>
                            </ul>
                            <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                                style={{ position: 'relative' }}>LOGIN</Button>
                        </form>
                    </div>
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
            <FeedbackMessages />
        </React.Fragment>
    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/login" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default Login;