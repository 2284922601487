import Layout from './hoc/Layout/Layout';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import * as actions from './store/actions/actions';
import Home from './containers/Home/Home'
import AboutMe from './containers/AboutMe/AboutMe'
import Skills from './containers/Skills/Skills'
import MyWork from './containers/MyWork/MyWork'
import ContactMe from './containers/ContactMe/ContactMe'
import Login from './containers/Admin/Login/Login'
import AdminPanel from './containers/Admin/AdminPanel/AdminPanel';

import React, { useState, useEffect } from "react";
import "./App.css";
import Toolbar from "./components/Toolbar/Toolbar";
import TransitionScreen from './components/UI/TransitionScreen/TransitionScreen';
import { useDispatch } from "react-redux";
import { AuthProvider } from './context/AuthContext';
import ReactGA from 'react-ga4';

ReactGA.initialize(`${process.env.REACT_APP_GA_ID}`);

const App = props => {
    const [transitionScreen, setTransitionScreen] = useState(false)
    const dispatch = useDispatch();
    const onRouteTransition = (value) => dispatch(actions.RouteTransition(value))
    const onNextRoutePath = (value) => dispatch(actions.NextRoutePath(value))
    const transitionIn = () => {
        setTransitionScreen(false);
        onRouteTransition(false);
    }
    const transitionOut = () => {

        setTransitionScreen(true);
        onRouteTransition(true);
    }

    const location = useLocation();

    useEffect(() => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);
    
    return (
        <React.Fragment>
            <Route
                render={({ location }) => {

                    onNextRoutePath(location.pathname)

                    return (
                        <AuthProvider>
                            <Toolbar />
                            <TransitionGroup component={null}>
                                <CSSTransition
                                    key={location.key}
                                    // nodeRef={nodeRef}
                                    classNames="fade"
                                    onEntered={() => transitionIn()}
                                    onExiting={() => transitionOut()}
                                    timeout={{ enter: 3000, exit: 500 }}>
                                    <Layout>
                                        <Switch location={location}>
                                            <Route path="/about"
                                                component={AboutMe} />
                                            <Route path="/skills"
                                                component={Skills} />
                                            <Route path="/myWork"
                                                component={MyWork} />
                                            <Route path="/contactMe"
                                                component={ContactMe} />
                                            <Route path="/login"
                                                component={Login} />
                                            <Route path="/adminPanel"
                                                component={AdminPanel} />
                                            <Route path="/" exact
                                                component={Home} />
                                            <Redirect to="/" />
                                        </Switch>
                                    </Layout>
                                </CSSTransition>
                            </TransitionGroup>
                        </AuthProvider>)
                }}
            />
            <TransitionScreen playAnim={transitionScreen} />
        </React.Fragment>
    );
}

export default App;
