import { createContext, useState, useEffect, useCallback } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // Load the initial state from localStorage
  const [isAuth, setIsAuth] = useState(() => {
    const storedAuth = localStorage.getItem('isAuth');
    return storedAuth ? JSON.parse(storedAuth) : false;
  });

  const [token, setToken] = useState(() => {
    return localStorage.getItem('token') || null;
  });

  const logout = useCallback(() => {
    setIsAuth(false);
    setToken(null);
    localStorage.removeItem("isAuth");
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("expiryDate");

  }, []);
  const setAutoLogout = useCallback((milliseconds) => {
    setTimeout(() => {
      logout();
    }, milliseconds);
  }, [logout]);

  // Update localStorage whenever isAuth or token changes
  useEffect(() => {
    localStorage.setItem('isAuth', JSON.stringify(isAuth));
    localStorage.setItem('token', token);

    const expiryDate = localStorage.getItem('expiryDate');
    if (!token || !expiryDate) {
      return;
    }
    if (new Date(expiryDate) <= new Date()) {
      logout();
      return;
    }
    const remainingMilliseconds =
      new Date(expiryDate).getTime() - new Date().getTime();

    setAutoLogout(remainingMilliseconds);

  }, [isAuth, token, logout, setAutoLogout]);


  return (
    <AuthContext.Provider value={{ isAuth, setIsAuth, token, setToken, logout, setAutoLogout }}>
      {children}
    </AuthContext.Provider>
  );
};



