import React, { useState, useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import styles from './AdminPanel.module.css';
import stylesTag from "../../../components/UI/Tag/Tags.module.css";

import Tag from "../../../components/UI/Tag/Tag";
import FeedbackMessages from "../../../components/UI/FeedbackMessage/FeedbackMessages";
import AdminToolbar from "../AdminToolbar/AdminToolbar";
import AdminGeneralInfo from "../AdminGeneralInfo/AdminGeneralInfo";
import AdminMyWork from "../AdminMyWork/AdminMyWork";
import AdminSkills from "../AdminSkills/AdminSkills";

import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from '../../../context/AuthContext';
import * as actions from "../../../store/actions/actions";

const AdminPanel = () => {
   
    const { isAuth, token, logout } = useContext(AuthContext);

    const [activeSection, setActiveSection] = useState(0);

    const history = useHistory();

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);


    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });



    useEffect(() => {
        const graphqlQuery = {
            query: `
             {
                tokenCheck
            }
            `
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {
                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

            }).catch(err => {
                // console.log(err);
                logout();
                history.push('/login');
            })
        } else {
            history.push('/login'); // Redirect to root if authenticated
        }

    }, [isAuth, history, addFeedbackMessage, token, logout]);

    const selectedContent = () => {
        switch (activeSection) {
            case 0:
                return <AdminGeneralInfo />
            case 1:
                return <AdminMyWork />
            case 2:
                return <AdminSkills />
            default:
                return <AdminGeneralInfo />
        }
    };

    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
            <div className={styles.ContactMeContainer}>
                <div className={styles.TextZone}>
                    <AdminToolbar activeSection={activeSection} setActiveSection={setActiveSection} />
                    {selectedContent()}
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
            <FeedbackMessages />
        </React.Fragment>
    return (
        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/adminPanel" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default AdminPanel;