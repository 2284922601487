import React, { useRef } from "react";
import styles from "./ImageUploaderElement.module.css"
import iconDelete from "../../../../assets/Icons/Icon_Delete.png"


const ImageUploaderElement = (props) => {

    const rectRef = useRef(null);
    // const [midpoint, setMidpoint] = useState(0);

    const handleDragStart = () => {
        if (!props.isDragging)
            props.onDragStart(props.index); // Notify parent when dragging starts
    };

    const handleDragEnd = () => {
        if (props.isDragging)
            props.onDragEnd();
    };

    const handleDragLeave = () => {
        if (props.isDragging)
            props.onDrag(-1);
    };

    const handleDragOver = (event) => {
        event.preventDefault();

        if (rectRef.current) {
            const rect = rectRef.current.getBoundingClientRect();
            const midpoint = rect.left + rect.width / 2;

            const cursorX = event.clientX;
            const newIndex = cursorX < midpoint ? props.index : props.index + 1;
            props.onDrag(newIndex);
        }

    };

    const element = (
        <div
            ref={rectRef} // Assign the ref here
            key={props.index}
            className={styles.ImageWrapper}
            draggable
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDragEnd={handleDragEnd}
        >
            {props.children}
            <img src={props.img} alt={`Uploaded-${props.index}`} className={styles.UploadedImage} />
            <button type="button" className={styles.ButtonIconDelete} onClick={(e) => props.onDelete(props.index)}>
                <img src={iconDelete} alt={`Delete-${props.index}`} className={styles.IconDelete} />
            </button>
        </div>
    );


    return (
        <React.Fragment>
            {element}
        </React.Fragment>
    )
}



export default ImageUploaderElement;