import React, { useState, useCallback, useContext, useEffect } from "react";
import styles from './AdminGame.module.css';

import { useDispatch } from "react-redux";
import { AuthContext } from '../../../../context/AuthContext';

import * as actions from "../../../../store/actions/actions";
import Button from '../../../../components/UI/Button/Button';
import AdminGameCreate from "./AdminGameCreate";
import GeneralElement from "../../../../components/UI/GeneralElement/GeneralElement";

const AdminGames = () => {

    const { token } = useContext(AuthContext);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const [refreshGames, setRefreshGames] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState({
        state: false,
        id: null
    });

    const [games, setGames] = useState([]);

    const createHandler = () => {
        setIsCreating(true);
    }

    const backToGamesHandler = useCallback(() => {
        setIsCreating(false);
        setIsEditing({
            state: false,
            id: null
        });
        setRefreshGames((prev) => !prev);
    }, []);

    const editHandler = (id) => {
        setIsEditing({
            state: true,
            id: id
        });
    }

    const deleteHandler = (id) => {
        const graphqlQuery = {
            query: `
            mutation DeleteGame($id:ID!){
            deleteGame(id:$id)
            }
            `
            , variables: {
                id: id
            }
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                if (data.data.deleteGame) {
                    addFeedbackMessage({ typeMessage: "sent", message: "DELETED" });
                    setRefreshGames((prev) => !prev);
                } else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }

            }).catch(err => {
                // console.log(err);
            })
        }
    }


    useEffect(() => {
        const graphqlQuery = {
            query: `
            query Games{
            games{
            games {
                _id
                title
                orderPrio
                duration
                imageUrl
            }
            }
            }
            `
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                setGames(data.data.games.games);

            }).catch(err => {
                // console.log(err);
            })
        }

    }, [token, addFeedbackMessage, refreshGames]);

    let pageContent;


    if (isCreating) {
        pageContent = <AdminGameCreate />
    }
    else
        if (isEditing.state) {
            pageContent = <AdminGameCreate isEditing={true} id={isEditing.id} />
        }
        else {
            pageContent = (
                <React.Fragment>
                    <div className={styles.TablesColumns}>
                        <div className={styles.GameOrderPrio}># Prio</div>
                        <div className={styles.GameTitle} >Name</div>
                        {/* <div className={styles.GameOptional}>Duration</div> */}

                    </div>
                    {games.map((game) => (
                        <GeneralElement
                            key={game._id} // Use a unique identifier, such as game.id, if available
                            id={game._id}
                            title={game.title}
                            imageUrl={game.imageUrl}
                            orderPrio={game.orderPrio}
                            // optional={game.duration}
                            onEdit={() => editHandler(game._id)}
                            onDelete={() => deleteHandler(game._id)}
                        />
                    ))}
                </React.Fragment>

            );
        }


    return (
        <React.Fragment>
            {!isCreating && !isEditing.state ?
                <h1>
                    GAMES
                </h1> :
                ""
            }

            <div className={styles.Container}>
                <div className={styles.ContainerElement}>
                    <div className={styles.ElementRight}>
                        {!isCreating && !isEditing.state ?
                            <Button btnType="normal" func={createHandler} classes={''}>+ Create</Button> :
                            <Button btnType="normal" func={backToGamesHandler} classes={''}>Back To Games</Button>
                        }
                    </div>
                </div>
                <div className={styles.ContainerElement}>
                    {pageContent}
                </div>
            </div>


        </React.Fragment>
    )
}


export default AdminGames;