import React, {useState, useEffect} from "react";
import styles from './FeedbackMessage.module.css'
import Spinner from "../../Spinner/Spinner";
import iconValid from '../../../../assets/Icons/iconValid.png'
import iconError from '../../../../assets/Icons/iconError.png'
import {Transition} from 'react-transition-group';

const FeedbackMessage = (props) => {
    const [classesAnim, setClassesAnim] = useState(styles.OpenAnim)
    const [show, setShow] = useState(true);
    const nodeRef = React.useRef(null);

    let icon = <Spinner/>;
    let text = "";
    switch (props.type) {
        case "sending": {
            icon = <Spinner/>;
            text = props.message;
            break;
        }
        case "sent": {
            icon = <img className={styles.Icon} src={iconValid} alt=""/>;
            text = props.message;
            break;
        }
        case "error": {
            icon = <img className={styles.Icon} src={iconError} alt=""/>;
            text = props.message;
            break;
        }
        default: {

        }
    }

    useEffect(() => {
        const closeAnimationTimer = setTimeout(() => {
            // classAnim = styles.CloseAnim;
            setClassesAnim(styles.CloseAnim)
            const hideTimer = setTimeout(() => {
                // props.removeItem(props.id);
                setShow(false)
            }, 1000)
            return () => clearTimeout(hideTimer);

        }, 2000)
        return () => clearTimeout(closeAnimationTimer);
    }, [])


    return (
        <Transition
            nodeRef={nodeRef}
            in={show}
            timeout={1000}
            mountOnEnter
            unmountOnExit>
            {
                (<div id={props.id} className={[styles.FeedbackMessageContainer, classesAnim].join(' ')}>
                        <div className={styles.IconZone}>
                            {icon}
                        </div>
                        <div className={styles.TextZone}>
                            <div className={styles.Text}>
                                {text}
                            </div>
                        </div>
                    </div>
                )
            }

        </Transition>

    )
}
export default FeedbackMessage;