import React, { useState, useCallback, useContext, useEffect } from "react";
import styles from './AdminGeneralInfo.module.css';
import { updateObject, checkValidity } from '../../../shared/shared';

import { useDispatch } from "react-redux";
import { AuthContext } from '../../../context/AuthContext';

import Button from '../../../components/UI/Button/Button';
import * as actions from "../../../store/actions/actions";

const AdminGeneralInfo = () => {

    const { token } = useContext(AuthContext);

    const [fields, setFields] = useState({
        title: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        roles: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        aboutMe: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        skillsDesc: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        contactDesc: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        email: {
            value: "",
            validation: {
                required: "true",
                email: "true"
            },
            valid: false,
            touched: false
        },
        linkedin: {
            value: "",
            validation: {
                required: "true",
                minLength: "5"
            },
            valid: false,
            touched: false
        },
        locationLong: {
            value: "",
            validation: {
                required: "true",
                number: "true"
            },
            valid: false,
            touched: false
        },
        locationLat: {
            value: "",
            validation: {
                required: "true",
                number: "true"
            },
            valid: false,
            touched: false
        }
    });

    const [formIsValid, setFormIsValid] = useState(false);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const handleChange = (value, field) => {
        const updatedField = updateObject(fields[field], {
            value: value,
            valid: checkValidity(value, fields[field].validation),
            touched: true
        });
        const updatedFields = updateObject(fields, { [field]: updatedField });

        let formIsValid = true;
        for (let field in updatedFields) {
            formIsValid = updatedFields[field].valid && formIsValid;
        }

        setFormIsValid(formIsValid);
        setFields(updatedFields);
    }


    const validationClasses = (isValid, isTouched) => {
        if (isTouched && isValid)
            return [styles.Valid]
        else if (isTouched && !isValid)
            return [styles.Error]
    }


    useEffect(() => {
        const graphqlQuery = {
            query: `
            {
            generalInfoData{
                title
                roles
                aboutMe
                skillsDesc
                contactDesc
                email
                linkedin
                locationLong
                locationLat
            }}
            `
        };

        if (token) {
            fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token,
                },
                body: JSON.stringify(graphqlQuery)
            }).then(res => {
                return res.json();
            }).then(data => {

                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                // Batch updates to fields state
                setFields(prevFields => ({
                    ...prevFields,
                    title: {
                        ...prevFields.title,
                        value: data.data.generalInfoData.title,
                        valid: checkValidity(data.data.generalInfoData.title, prevFields.title.validation),
                        touched: true
                    },
                    roles: {
                        ...prevFields.roles,
                        value: data.data.generalInfoData.roles,
                        valid: checkValidity(data.data.generalInfoData.roles, prevFields.roles.validation),
                        touched: true
                    },
                    aboutMe: {
                        ...prevFields.aboutMe,
                        value: data.data.generalInfoData.aboutMe,
                        valid: checkValidity(data.data.generalInfoData.aboutMe, prevFields.aboutMe.validation),
                        touched: true
                    },
                    skillsDesc: {
                        ...prevFields.skillsDesc,
                        value: data.data.generalInfoData.skillsDesc,
                        valid: checkValidity(data.data.generalInfoData.skillsDesc, prevFields.skillsDesc.validation),
                        touched: true
                    },
                    contactDesc: {
                        ...prevFields.contactDesc,
                        value: data.data.generalInfoData.contactDesc,
                        valid: checkValidity(data.data.generalInfoData.contactDesc, prevFields.contactDesc.validation),
                        touched: true
                    },
                    email: {
                        ...prevFields.email,
                        value: data.data.generalInfoData.email,
                        valid: checkValidity(data.data.generalInfoData.email, prevFields.email.validation),
                        touched: true
                    },
                    linkedin: {
                        ...prevFields.linkedin,
                        value: data.data.generalInfoData.linkedin,
                        valid: checkValidity(data.data.generalInfoData.linkedin, prevFields.linkedin.validation),
                        touched: true
                    },
                    locationLong: {
                        ...prevFields.locationLong,
                        value: data.data.generalInfoData.locationLong,
                        valid: checkValidity(data.data.generalInfoData.locationLong, prevFields.locationLong.validation),
                        touched: true
                    },
                    locationLat: {
                        ...prevFields.locationLat,
                        value: data.data.generalInfoData.locationLat,
                        valid: checkValidity(data.data.generalInfoData.locationLat, prevFields.locationLat.validation),
                        touched: true
                    }
                }));

            }).catch(err => {
                // console.log(err);
            })
        }

    }, [token, addFeedbackMessage]);

    const submitHandler = (event) => {
        event.preventDefault();

        addFeedbackMessage({ typeMessage: "sending", message: "UPDATING" });
        const graphqlQuery = {
            query: `
            mutation updateGeneralInfo($generalInfoInput: GeneralInfoInputData!){
                updateGeneralInfoData(generalInfoInput: $generalInfoInput){
                title
                }
            }
            `,
            variables: {
                generalInfoInput: {
                    title: fields.title.value,
                    roles: fields.roles.value,
                    aboutMe: fields.aboutMe.value,
                    skillsDesc: fields.skillsDesc.value,
                    contactDesc: fields.contactDesc.value,
                    email: fields.email.value,
                    linkedin: fields.linkedin.value,
                    locationLong: Number(fields.locationLong.value),
                    locationLat: Number(fields.locationLat.value)
                }
            }
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then((data) => {
            // Handle the response data here
            // console.log(data);
            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }
            // console.log(data);
            addFeedbackMessage({ typeMessage: "sent", message: "UPDATED!" });
        })
            .catch((error) => {

                // console.error("Error during fetch:", error);
                addFeedbackMessage({ typeMessage: "error", message: "An error occurred during login. Please try again." });
            });


    }

    const pageContent = (
        <React.Fragment>
            <h1>
                GENERAL INFORMATION
            </h1>
            <div className={styles.ContactForm}>
                <form onSubmit={submitHandler} autoComplete={"off"}>
                    <ul>
                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Title</label>
                            <input
                                name="title"
                                type="text"
                                placeholder="Title"
                                onChange={(event) => handleChange(event.target.value, "title")}
                                value={fields["title"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["title"].valid, fields["title"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Roles</label>
                            <input
                                name="roles"
                                type="text"
                                placeholder="Roles"
                                onChange={(event) => handleChange(event.target.value, "roles")}
                                value={fields["roles"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["roles"].valid, fields["roles"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI3Animation].join(' ')}>
                            <label className={[styles.LabelName]}>About Me</label>
                            <textarea
                                name="aboutMe"
                                type="text"
                                placeholder="About Me"
                                onChange={(event) => handleChange(event.target.value, "aboutMe")}
                                value={fields["aboutMe"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["aboutMe"].valid, fields["aboutMe"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Skills Description</label>
                            <textarea
                                name="skillsDesc"
                                type="text"
                                placeholder="Skill Description"
                                onChange={(event) => handleChange(event.target.value, "skillsDesc")}
                                value={fields["skillsDesc"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["skillsDesc"].valid, fields["skillsDesc"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI1Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Contact Description</label>
                            <textarea
                                name="contactDesc"
                                type="text"
                                placeholder="Contact Description"
                                onChange={(event) => handleChange(event.target.value, "contactDesc")}
                                value={fields["contactDesc"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["contactDesc"].valid, fields["contactDesc"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Email</label>

                            <input
                                name="email"
                                type="email"
                                placeholder="email"
                                onChange={(event) => handleChange(event.target.value, "email")}
                                value={fields["email"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["email"].valid, fields["email"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Linkedin</label>
                            <input
                                name="linkedin"
                                type="text"
                                placeholder="linkedin"
                                onChange={(event) => handleChange(event.target.value, "linkedin")}
                                value={fields["linkedin"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["linkedin"].valid, fields["linkedin"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI1Animation].join(' ')} style={{ marginRight: "2%" }}>
                            <label className={[styles.LabelName]}>Location Longitude</label>
                            <input
                                name="locationLong"
                                type="text"
                                placeholder="locationLong"
                                onChange={(event) => handleChange(event.target.value, "locationLong")}
                                value={fields["locationLong"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["locationLong"].valid, fields["locationLong"].touched)].join(' ')}>
                            </label>
                        </li>
                        <li className={[styles.Half, styles.ContactFormLI2Animation].join(' ')}>
                            <label className={[styles.LabelName]}>Location Latitude</label>
                            <input
                                name="locationLat"
                                type="text"
                                placeholder="locationLat"
                                onChange={(event) => handleChange(event.target.value, "locationLat")}
                                value={fields["locationLat"].value} />
                            <label
                                className={[styles.LabelFeedBack, validationClasses(fields["locationLat"].valid, fields["locationLat"].touched)].join(' ')}>
                            </label>
                        </li>
                    </ul>
                    <Button classes={styles.ButtonAnimation} btnType="normal" disabled={!formIsValid}
                        style={{ position: 'relative' }}>SAVE</Button>
                </form>
            </div>
        </React.Fragment>

    );


    return (
        <React.Fragment>
            {pageContent}
        </React.Fragment>
    )
}


export default AdminGeneralInfo;