import React, { useEffect, useState, useCallback } from "react";

import * as actions from "../../../../store/actions/actions";
import { useDispatch } from "react-redux";
import styles from "./ProjectsListWeb.module.css"

import ProjectWeb from "./Project/ProjectWeb";

const ProjectsListWeb = (props) => {

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    const [projectsData, setProjectsData] = useState();

    useEffect(() => {

        const fetchWebs = async () => {

            const graphqlQuery = {
                query: `
            query Webs{
            webs{
            webs {
                _id
                title
                description
                imageUrl
                link
                images
            }
            }
            }
            `
            };

            try {

                const response = await fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(graphqlQuery)
                })

                const data = await response.json();


                // console.log(data);

                if (data.errors) {
                    if (data.errors[0].extensions) {
                        addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                        const err = new Error(data.errors[0].extensions.message)
                        throw err;
                    }
                    else {
                        addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                        const err = new Error("An Error Occured!")
                        throw err;
                    }
                }

                setProjectsData(data.data.webs.webs);


            } catch (err) {
                // console.log(err);
            }
        }

        fetchWebs();

    }, [addFeedbackMessage]);

    const [index, setIndex] = useState(0)
    const [projectDataArray, setProjectDataArray] = useState([]);

    useEffect(() => {
        if (!projectsData)
            return;
        const timeout = setTimeout(() => {
            setProjectDataArray(projectDataArray.concat(projectsData[index]))
            if (index < projectsData.length - 1)
                setIndex(index + 1)

        }, 300);

        return () => clearTimeout(timeout)
        // eslint-disable-next-line
    }, [index, projectsData])

    let projects;
    if (projectsData && projectsData.length > 0)
        projects = projectDataArray.map(project => (
            <ProjectWeb key={project.title} thumbnail={project.imageUrl} title={project.title}
                description={project.description}
                images={project.images}
                link={project.link} />
        ))

    let styleClasses = [styles.ProjectsList];
    if (props.styles)
        styleClasses.push(props.styles)

    return (
        <div className={styleClasses.join(' ')}>
            {projects}
        </div>
    )
}

export default ProjectsListWeb;