import React from "react";
import styles from "./AdminToolbar.module.css";

const AdminToolbar = (props) => {



    const onClickBtnSection = (index) => {
        props.setActiveSection(index);
    };

    const classBtnActive = () => {
        switch (props.activeSection) {
            case 0:
                return null;
            case 1:
                return styles.UnderlineActiveBtn1;
            case 2:
                return styles.UnderlineActiveBtn2;
            default:
                return null;
        }
    }


    const pageContent =
        (<div className={styles.SectionSelection}>
            <div>
                <span onClick={() => onClickBtnSection(0)}
                    className={[styles.SectionBtn, props.activeSection === 0 ? styles.SectionBtnActive : null].join(' ')}>
                    <span
                        className={styles.SectionSelectionBtn0}>General Info</span></span>
                <span onClick={() => onClickBtnSection(1)}
                    className={[styles.SectionBtn, props.activeSection === 1 ? styles.SectionBtnActive : null].join(' ')}>
                    <span className={styles.SectionSelectionBtn1}>My Work</span></span>
                <span onClick={() => onClickBtnSection(2)}
                    className={[styles.SectionBtn, props.activeSection === 2 ? styles.SectionBtnActive : null].join(' ')}>
                    <span className={styles.SectionSelectionBtn1}>Skills</span></span>
            </div>
            <div
                className={[styles.UnderlineActiveBtn, classBtnActive()].join(' ')}>
            </div>
        </div>)



    return (
        <React.Fragment>
            {pageContent}
        </React.Fragment>
    )
}


export default AdminToolbar;