export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

const MAX_FILE_SIZES = {
    Skill: 200 * 1024,  // 200kB
    Game: 500 * 1024,   // 500kB
    Web: 500 * 1024,    // 500kB
    WebGallery: 2 * 1024 * 1024, // 2MB
};

const ALLOWED_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif'];

export const checkValidity = (value, rules, files, category) => {

    let isValid = true;

    if (rules.required) {
        isValid = (value !== null && value !== undefined && value.toString().trim() !== '') && isValid;
    }

    if (rules.number) {
        isValid = !isNaN(value) && isFinite(value) && isValid;
    }

    if (rules.minLength) {
        isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
        isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValid = re.test(String(value).toLowerCase());
    }

    if (rules.requiredFile) {
        // If there is value it means we are editing and no need for validation since there is already an old file link in the database
        if (!(value !== null && value !== undefined && value.toString().trim() !== '')) {
            const file = files[0];
            const fileSizeLimit = MAX_FILE_SIZES[category];
            isValid = file.size <= fileSizeLimit && isValid;
            isValid = ALLOWED_MIME_TYPES.includes(file.type) && isValid;
        }
    }

    return isValid;
}