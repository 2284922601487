import React, { useEffect, useState, useCallback } from "react";
import * as actions from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./AboutMe.module.css";
import chillingGIFWhite from '../../assets/ChillingWhite.gif'
import timelineImage from '../../assets/Timleline.png'
import Tag from "../../components/UI/Tag/Tag";
import stylesTag from "../../components/UI/Tag/Tags.module.css";

const AboutMe = () => {
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });
    
    const [paragraphs, setParagraphs] = useState([]);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    useEffect(() => {
        const graphqlQuery = {
            query: `
            {
            generalInfoData{
                aboutMe
            }}
            `
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then(data => {

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }
           
            setParagraphs(data.data.generalInfoData.aboutMe.split('\n\n'));

        }).catch(err => {
            // console.log(err);
        })

    }, [addFeedbackMessage]);

    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
            <div className={styles.AboutMeContainer}>

                <div className={styles.TextZone}>
                    <h1>About Me</h1>
                    <br />
                    <br />
                    <p className={styles.P1}>{paragraphs[0]}</p>
                    <p className={styles.P2}>{paragraphs[1]}</p>
                    <p className={styles.P3}>{paragraphs[2]}</p>
                    <p className={styles.P4}>{paragraphs[3]}</p>
                    <img className={styles.TimelineImage} src={timelineImage} alt="" />

                </div>
                <div className={styles.ImgZone}>
                    <img src={chillingGIFWhite} alt="" />
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
        </React.Fragment>
    return (

        <React.Fragment>
            {
                routeTransition ? nextRoutePath !== "/about" ? pageContent : null : pageContent
            }
        </React.Fragment>
    )
}

export default AboutMe;