import React, { useEffect, useState, useCallback } from "react";
import * as actions from "../../store/actions/actions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Skills.module.css";
import { NavLink } from 'react-router-dom';

import ProgressBars from './ProgressBars/ProgressBars';
import Tag from "../../components/UI/Tag/Tag";
import stylesTag from "../../components/UI/Tag/Tags.module.css";

const Skills = () => {
    const routeTransition = useSelector(state => {
        return state.routeTransition;
    });
    const nextRoutePath = useSelector(state => {
        return state.nextRoutePath;
    });

    const [fields, setFields] = useState({
        skillsDesc: "",
        linkedin: ""
    });

    const [skills, setSkills] = useState([]);

    const [paragraphs, setParagraphs] = useState([]);

    const dispatch = useDispatch();
    const addFeedbackMessage = useCallback((feedbackMessage) => dispatch(actions.AddFeedback(feedbackMessage)), [dispatch]);

    useEffect(() => {
        const graphqlQuery = {
            query: `
            query GetData{
                generalInfoData{
                    skillsDesc,
                    linkedin
                },
                skills{
                    skills {
                        _id
                        title
                        skillLevel
                        imageUrl
                    }
                }
            }
            `
        };

        fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(graphqlQuery)
        }).then(res => {
            return res.json();
        }).then(data => {

            // console.log(data);

            if (data.errors) {
                if (data.errors[0].extensions) {
                    addFeedbackMessage({ typeMessage: "error", message: data.errors[0].extensions.message });
                    const err = new Error(data.errors[0].extensions.message)
                    throw err;
                }
                else {
                    addFeedbackMessage({ typeMessage: "error", message: "An Error Occured!" });
                    const err = new Error("An Error Occured!")
                    throw err;
                }
            }

            // Batch updates to fields state
            setFields({
                skillsDesc: data.data.generalInfoData.skillsDesc,
                linkedin: data.data.generalInfoData.linkedin,
            });

            setParagraphs(data.data.generalInfoData.skillsDesc.split('\n\n'));

            setSkills(data.data.skills.skills);

        }).catch(err => {
            // console.log(err);
        })

    }, [addFeedbackMessage]);

    const pageContent =
        <React.Fragment>
            <Tag classes={stylesTag.HtmlTopTag} closed={false} text='html' />
            <Tag classes={stylesTag.BodyTopTag} closed={false} text='body' />
            <div className={styles.SkillsContainer}>

                <div className={styles.TextZone}>
                    <h1>Skills & Experience</h1>
                    <br />
                    <br />
                    <p className={styles.P1}>{paragraphs[0]}</p>
                    <p className={styles.P2}>{paragraphs[1]}</p>
                    <p className={styles.P3}>{paragraphs[2]}</p>

                    <p className={styles.P4}>Visit my <a className={styles.Navlink} target="_blank" rel="noreferrer"
                        href={fields["linkedin"]}>LinkedIn</a> profile
                        for
                        more
                        details or just <NavLink to={"contactMe"} className={styles.Navlink}>Contact
                            Me</NavLink>.</p>


                </div>
                <div className={styles.SkillsBarContainer}>
                    <ProgressBars skills={skills}/>
                </div>
            </div>
            <Tag classes={stylesTag.BodyBottomTag} closed={true} text='body' />
            <Tag classes={stylesTag.HtmlBottomTag} closed={true} text='html' />
        </React.Fragment>

    return (
        <React.Fragment>

            <React.Fragment>
                {
                    routeTransition ? nextRoutePath !== "/skills" ? pageContent : null : pageContent
                }
            </React.Fragment>
        </React.Fragment>
    )
}

export default Skills;